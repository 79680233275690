import { memo, useState } from 'react';

import ChevronDown from '@assets/chevron-down.svg?react';
import ArrowRight from '@assets/arrow-right.svg?react';
import styles from './FAQCollapsibleItem.module.scss';
import { Tooltip } from '@components/common';
import { openContactForm } from '@components/ContactModal';

export type Props = {
    question: string;
    answer: string;
    answeredBy: {
        name: string;
        avatar: ImageMetadata;
        avatarFallback: ImageMetadata;
        position: string;
        about: string;
    };
};

const FAQCollapsibleItem = ({ question, answer, answeredBy }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const onToggle = () => setIsOpen(prev => !prev);

    const onClick = (e: any) => {
        e.stopPropagation();

        openContactForm({
            title: 'Kostenlose Beratung',
            description: 'Wir melden uns bei dir bezüglicher einer kostenlosen Beratung.',
            convertActionTitle: 'Nachricht schicken',
        });
    };

    return (
        <div
            className={
                styles.faqCollapsibleItem + (isOpen ? ' ' + styles.faqCollapsibleItemOpened : '')
            }
            onClick={onToggle}>
            <Tooltip
                delay={300}
                contents={
                    <div className={styles.faqCollapsibleItemAvatar}>
                        <picture>
                            <source srcSet={answeredBy.avatar.src} type="image/webp" />
                            <img src={answeredBy.avatarFallback.src} alt={answeredBy.name} />
                        </picture>
                    </div>
                }>
                <div className={styles.faqCollapsibleItemPopover}>
                    <strong>{answeredBy.name}</strong>
                    <span>{answeredBy.position}</span>

                    <button className={styles.faqCollapsibleItemContactButton} onClick={onClick}>
                        Kontaktieren
                        <ArrowRight />
                    </button>
                </div>
            </Tooltip>

            <div className={styles.faqCollapsibleItemContents}>
                <span className={styles.faqCollapsibleItemQuestion}>{question}</span>
                <div className={styles.faqCollapsibleItemAnswer}>
                    <p dangerouslySetInnerHTML={{ __html: answer }} />

                    <div className={styles.faqCollapsibleItemFooter}>
                        <div className={styles.faqCollapsibleItemAbout}>
                            <strong>{answeredBy.name}</strong>
                            <span>{answeredBy.position}</span>
                        </div>

                        <button
                            className={styles.faqCollapsibleItemContactButton}
                            onClick={onClick}>
                            Kontaktieren
                            <ArrowRight />
                        </button>
                    </div>
                </div>
            </div>

            <span className={styles.faqCollapsibleItemIcon}>
                <ChevronDown />
            </span>
        </div>
    );
};

// const transition = {
//     type: 'spring',
//     bounce: 0.2,
//     duration: 0.5,
// };
//
// const answerVariants: Variants = {
//     close: {
//         height: '0px',
//         marginTop: '0px',
//     },
//     open: {
//         height: 'auto',
//         marginTop: '50px',
//     },
// };

export default memo(FAQCollapsibleItem);
